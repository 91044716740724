// Spinner/Loader animation
@-moz-keyframes animate-spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes animate-spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes animate-spin {
  from {
    transform:rotate(0deg);}
  to {
    transform:rotate(360deg);
  }
}
