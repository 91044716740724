@import 'Animation.scss';

@font-face {
  font-family: 'SimianTextGorilla';
  src: url(../fonts/simtgori.AH.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'SimianTextOrangutan';
  src: url(../fonts/simtoran.AH.woff) format("woff");
  font-display: swap;
}

* {
  font-family: SimianTextOrangutan;
}
 a {
   color: white;
  text-decoration: underline;
  &:hover{
    color: lightblue;
    font-weight: bold;
  }
 }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: darkslateblue;
  font-family: SimianTextOrangutan;
  font-size: calc(13px + 0.5vw); // 1.5vw;
  padding: 1em 2em;
}

.button, .button-blue {
  background-color: black;
  border: 0px;
  border-radius: 3px;
  color: white;
  font-family: inherit;
  font-size: 1em;
  margin: auto;
  padding: 0.1em 2em;
  text-decoration: none;
}
.button:disabled, .button-blue:disabled {
  background-color: gray;
}
.button:hover:not([disabled]), .button:not([disabled]):active, .button-blue:not([disabled]):active, .button-blue:hover:not([disabled]) {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-weight: 800;
  text-decoration: none;
}
.button-blue {
  background-color: #5366ad;
}
.button-link {
  background: none !important;
  border: none;
  color: white;
  cursor: pointer;
  font-size: inherit;
  padding: 0 !important;
  text-decoration: underline;
  &:hover {
    color: #5366ad;
  }
}

.content {
  color: white;
  padding: 0;
}

.display-none {
  display: none;
}

.error-message {
  color: #db4437;//tomato;
}

#footer {
  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-decoration: underline;
    &:hover{
      color: lightblue;
      font-weight: bold;
    }
  }
  padding: 2em;
}

.form-card {
  margin: auto;
  padding: 10px;
  text-align: left;
  width: 20em;
}

.form-input {
  background-color: rgba(white, 0.9);
  border: transparent;
  border-radius: 5px;
  font-size: 1em;
  padding: 3px 10px;
  width: 20em;
}

h1, h2, h3, h4 {
  // font-family: SimianTextGorilla;
  font-weight: bold;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

H2 {
  font-family: SimianTextGorilla;
  font-weight: normal;
}

.header {
  background-color: rgba(white, 0.2);
  border-radius: 30px;
  margin: auto;
  padding: 1.5em 2em;
  width: 75%;
}

.logo {
  min-width: 12em;
  width: 20%;
}

.products {
  img {
    aspect-ratio: 16/9;
    border: 3px solid white;
    border-radius: 10px;
    margin: 1em 1em;
    max-width: 43%;//280px;
  }
}

.spinner-inline {
  background-color: #61dafb; //lightblue;
  display: inline-block;
  height: 1.25em;
  mask-image: url("../images/icons/loader.svg");
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  position: relative;
  transform: translate(5px, 0.5em);
  width: 1.25em;
  z-index: 21 !important;
  
  -webkit-animation-name: animate-spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: animate-spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: animate-spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  
  animation-name: animate-spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  &:before {
    content: "";
    background-color: black;
    width: 1em;
  }
}

.step {
  padding: 1em;
  button {
    margin: 0 10px;
  }
}
